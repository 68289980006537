class Helper {
    mapFields(oldValue, newValue) {
        for (var prop in oldValue) {
            if (newValue.hasOwnProperty(prop)) {
                oldValue[prop] = newValue[prop];
            }
        }
    }

    userAvatar(avatar) {
        if (avatar) {
            return (
                process.env.VUE_APP_ImagesPath +
                "/avatars/" +
                avatar
            );
        }
        return "";
    }

    isNumber(val){
        return typeof val === 'number' && !isNaN(val);
    }

    treeNodeNormalizer(node) {
        if (node.data?.nodeType === 0) {
            return {
                id: "c" + node.id,
                label: node.label
            };
        }
        return node;
    }
}

export default new Helper();