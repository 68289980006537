<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="profile-element">
            <img height="30px;" src="images/logo_w.svg" />
          </div>
          <div class="logo-element">
            <img height="38px;" src="images/logo_w_s.svg" />
          </div>
        </li>
        <li v-for="item in items" v-bind:key="item.path" v-bind:class="item.class">
          <router-link :to="item.path" v-if="item.path">
            <i v-bind:class="item.icon"></i>
            <span class="nav-label">{{ item.title }}</span>
          </router-link>
          <template v-else>
            <a href="#">
              <i v-bind:class="item.icon"></i>
              <span class="nav-label">{{ item.title }}</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li v-for="subitem in item.childs" v-bind:key="subitem.path">
                <router-link :to="subitem.path">{{
          subitem.title
        }}</router-link>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";

export default {
  name: "NavigationMenu",
  data() {
    return {
      navigationMenuStructure: [
        // {
        //   name: "sale",
        //   title: "Продажи",
        //   icon: "fa fa-money"
        // },
        // {
        //   name: "cashShift",
        //   title: "Кассовая смена",
        //   icon: "fa fa-money"
        // },
        // {
        //   name: "mycashshifts",
        //   title: "Мои смены",
        //   icon: "fa fa-files-o"
        // },
        {
          name: "dashboard",
          title: "Главная",
          icon: "fa fa-th-large",
        },
        {
          name: "moneyTransactions",
          title: "Операции",
          icon: "fa fa-money",
        },
        {
          name: "leads",
          title: "Лиды",
          icon: "fa fa-address-card-o",
        },
        {
          name: "customers",
          title: "Клиенты",
          icon: "fa fa-address-card",
        },
        {
          name: "adminCompanies",
          title: "Компании",
          icon: "fa fa-university",
        },
        {
          name: "projects",
          title: "Проекты",
          icon: "fa fa-folder",
        },
        {
          name: "orders",
          title: "Заказы",
          icon: "fa fa-clipboard",
        },
        {
          name: "purchaseRequests",
          title: "Комплектация заказов",
          icon: "fa fa-shopping-cart",
        },
        {
          title: "Производство",
          icon: "fa fa-connectdevelop",
          class: "folder",
          submenu: [
            {
              name: "productionRequestsAdmin",
              title: "Заявки",
            },
            {
              name: "productionPlanAdmin",
              title: "План-график",
            },
          ],
        },
        {
          name: "calendar",
          title: "Календарь",
          icon: "fa fa-calendar",
        },
        {
          name: "issues",
          title: "Задачи",
          icon: "fa fa-tasks",
        },
        {
          title: "Складской учёт",
          icon: "fa fa-th-large",
          class: "folder",
          submenu: [
            {
              name: "productsDocuments",
              title: "Документы",
            },
            {
              name: "productsStock",
              title: "Товары на складе",
            }
          ],
        },
        {
          name: "productionRequests",
          title: "Заявки на производство",
          icon: "fa fa fa-connectdevelop",
        },
        {
          name: "productionPlan",
          title: "План-график производства",
          icon: "fa fa-dedent",
        },
        {
          name: "productsStockDesigner",
          title: "Товары на складе",
          icon: "fa fa-th-large",
        },
        {
          name: "salary",
          title: "Заработная плата",
          icon: "fa fa-calculator",
        },
        {
          title: "Аналитика",
          icon: "fa fa-line-chart",
          class: "folder",
          submenu: [
            {
              name: "summary",
              title: "Показатели",
            },
            {
              name: "сustomerSourcesReport",
              title: "Отчёт по клиентам",
            },
            {
              name: "ordersStatisticReport",
              title: "Отчёт по заказам",
            },
            {
              name: "orderInstanceTypeReport",
              title: "Отчёт по типам изделий",
            },
            {
              name: "salesBySuppliersReport",
              title: "Отчёт по поставщикам",
            },
            {
              name: "designersReport",
              title: "Отчёт по дизайнерам",
            },
          ],
        },
        {
          title: "Справочники",
          icon: "fa fa-folder-open",
          class: "folder",
          submenu: [
            {
              name: "products",
              title: "Каталог товаров"
            },
            {
              name: "provideServices",
              title: "Прайс-лист услуг",
            },
            {
              name: "orderInstanceTypes",
              title: "Типы изделий",
            },
            {
              name: "suppliers",
              title: "Поставщики",
            },
            {
              name: "expenseCategories",
              title: "Категории расходов",
            },
            {
              name: "paymentTypes",
              title: "Формы платежей",
            },
            {
              name: "pointOfSales",
              title: "Салоны",
            },
            {
              name: "stocks",
              title: "Склады",
            },
            {
              name: "rooms",
              title: "Помещения",
            },
            {
              name: "customerSources",
              title: "Источники клиентов",
            },
            {
              name: "closeProjectReasons",
              title: "Причины отклонения проектов",
            },
            {
              name: "measureUnits",
              title: "Единицы измерения",
            },

            // {
            //   name: "incomeTypes",
            //   title: "Типы прихода",
            //   roles: ["Admin"],
            // },
            // {
            //   name: "outcomeTypes",
            //   title: "Типы расхода",
            //   roles: ["Admin"],
            // },
          ],
        },
        {
          name: "employees",
          title: "Сотрудники",
          icon: "fa fa-users",
        },
        {
          name: "company",
          title: "Моя компания",
          icon: "fa fa-home",
        },
        {
          name: "settings",
          title: "Настройки",
          icon: "fa fa-gear",
        },
      ],
      items: [],
    };
  },
  mounted: function () {
    $("#side-menu").metisMenu({
      toggle: false,
    });

    $(".nav-second-level a").click(function (evt) {
      if ($("body").hasClass("mini-navbar")) {
        const folder = $(evt.target).parents(".folder");
        folder.children("a").trigger('click');
      }
    });
  },
  created() {
    let userRoles = this.$store.state.auth.user.role;
    let companyInfo = JSON.parse(localStorage.getItem("company-info"));
    this.navigationMenuStructure.forEach((menu) => {
      if (menu.name) {
        let route = this.$router.options.routes.find(
          (item) => item.name == menu.name
        );

        var isAllowAccess =
          userRoles.filter(function (n) {
            if (route.meta && route.meta.roles) {
              return route.meta.roles.indexOf(n) > -1;
            }
            return false;
          }).length > 0;

        if (route.meta && route.meta.permition && companyInfo.permissions.indexOf(route.meta.permition) < 0) {
          isAllowAccess = false;
        }
        if (isAllowAccess) {
          let path = route.path;
          if (route.path.lastIndexOf("/:") > 0) {
            path = route.path.substring(0, route.path.lastIndexOf("/:"));
          }

          let existMenu = false;
          if (path) {
            this.items.forEach((item) => {
              if (item.path === path) {
                existMenu = true;
              }
              item.childs.forEach((submenu) => {
                if (submenu.path === path) {
                  existMenu = true;
                }
              });
            });
          }

          if (!existMenu) {
            this.items.push({
              name: menu.name,
              path: path,
              title: menu.title,
              icon: menu.icon,
              class: menu.class,
              childs: [],
            });
          }
        }
      } else {
        let menuCategory = {
          title: menu.title,
          icon: menu.icon,
          class: menu.class,
          childs: [],
        };

        menu.submenu.forEach((submenu) => {
          let route = this.$router.options.routes.find(
            (item) => item.name == submenu.name
          );

          var isAllowAccess =
            userRoles.filter(function (n) {
              if (route.meta && route.meta.roles) {
                return route.meta.roles.indexOf(n) > -1;
              }
              return false;
            }).length > 0;
          if (route.meta && route.meta.permition && companyInfo.permissions.indexOf(route.meta.permition) < 0) {
            isAllowAccess = false;
          }
          if (isAllowAccess) {
            menuCategory.childs.push({
              name: submenu.name,
              path: route.path,
              title: submenu.title,
              icon: submenu.icon,
            });
          }
        });

        if (menuCategory.childs && menuCategory.childs.length > 0) {
          this.items.push(menuCategory);
        }
      }
    });

    return;
    this.$router.options.routes.forEach((route) => {
      var isAllowAccess =
        userRoles.filter(function (n) {
          if (route.meta && route.meta.roles) {
            return route.meta.roles.indexOf(n) > -1;
          }
          return false;
        }).length > 0;

      if (
        isAllowAccess ||
        (route.meta && route.meta.childs && route.meta.childs.length > 0)
      ) {
        this.items.push({
          name: route.name,
          path: route.path,
          title: route.meta.title,
          icon: route.meta.icon,
        });
      }
    });
  },
  computed: {
    currentUser() {
      if (!this.$store.state.auth.user) {
        return "";
      }
      return this.$store.state.auth.user;
    },
    avatarUrl() {
      if (this.$store.state.auth.user.avatar) {
        return (
          process.env.VUE_APP_ImagesPath +
          "/avatars/" +
          this.$store.state.auth.user.avatar
        );
      }
      return process.env.VUE_APP_ImagesPath + "/avatars/avatar.png";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav>li>a.router-link-exact-active {
  border-left: 4px solid #129399;
  background: #293846;
  color: #ffffff;
}

.nav>li.folder {
  border-left: 0;
  background: none;
}

.nav>li.folder>a {
  color: #a7b1c2;
}

.nav-second-level li:last-child {
  padding: 0;
}

ul .nav-second-level {
  background: none;
}

body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: block;
}
</style>
